<template>
    <Panel
        v-if="$route.meta.isCabinet"
        :title="title"
        ref="panel"
        @updated="panelUpdated"
        @langChanged="langChanged"
    />
    <router-view v-slot="{ Component }">
        <component
            ref="view"
            :is="Component"
            :inbox="info.inbox"
            :outbox="info.outbox"
            :current-plan="info.plan"
            :balance="info.balance"
            :lang="lang"
            @setPageTitle="setPageTitle"
            @updateCounters="updateCounters"
            @updateBalance="updateBalance"
            @updateMeta="updateMeta"
            @createMeta="setDescMeta"
            @getUnreadNotices="getUnreadNotices"
        />
    </router-view>
    <notifications position="bottom right" width="400px">
        <template #body="props">
            <div class="my-notification">
                <div class="row">
                    <div v-if="props.item.type" class="col-md-2 d-flex align-items-center justify-content-end">
                        <i class="bi fs-3" :class="{ 'bi-exclamation-triangle-fill text-danger': props.item.type==='error', 'bi-check-circle-fill text-success': props.item.type==='success', 'bi-exclamation-triangle-fill text-warning': props.item.type==='warn' }"></i>
                    </div>
                    <div v-if="props.item.type" class="col-md-8">
                        <h5 class="title">{{ props.item.title }}</h5>
                        <div v-html="props.item.text"/>
                    </div>
                    <div v-if="!props.item.type" class="col-md-10">
                        <h5 class="title">{{ props.item.title }}</h5>
                        <div v-html="props.item.text"/>
                    </div>
                    <div class="col-md-2 d-flex align-items-center justify-content-end">
                        <button class="btn btn-link close" @click="props.close">
                            <i class="bi bi-x-lg fs-4"></i>
                        </button>
                    </div>
                </div>
            </div>
        </template>
    </notifications>
    <Modal />
</template>

<script>
    import Panel from './components/cabinet/Panel.vue'
    import Modal from './components/Modal.vue'

    export default {
        data: () => ({
            title: `KDP`,
            metaDescription: '',
            lang: '',
            info: {
                inbox: 0,
                outbox: 0,
                balance: 0,
                plan: ''
            },
            isBlurred: JSON.parse(localStorage.getItem('isBlurred'))
        }),
        components: {
            Panel,
            Modal
        },
        methods: {
            langChanged(lang) {
                this.lang = lang
                this.$refs.view.langChange()
            },
            setPageTitle(title) {
                this.title = title
            },
            updateCounters() {
                this.$refs.panel.updateCounters()
            },
            updateBalance() {
                this.$refs.panel.updateBalance()
            },
            panelUpdated({ key, value }) {
                if(key in this.info) {
                    if(key != 'plan') {
                        this.info[key] = value
                    } else {
                        this.info[key] = value.name
                    }
                }
            },
            updateMeta(data = {}) {
                const title = document.title;

                if(!Object.keys(data).length) {
                    data = {
                        "meta": [
                            {
                                "name": "description",
                                "content": this.$t('head.meta.description')
                            },
                            {
                                "property": "og:title",
                                "content": this.$t('head.meta.og:title')
                            }
                        ]
                    }

                    if (title.includes('[DEV]')) {
                        data.title = '[DEV] ' + this.$t('head.title')
                    } else if (title.includes('[STAGE]')) {
                        data.title  = '[STAGE] ' + this.$t('head.title')
                    } else if (title.includes('[LOCAL]')) {
                        data.title  = '[LOCAL] ' + this.$t('head.title')
                    } else {
                        data.title  = this.$t('head.title')
                    }
                }

                this.updateMetaTags(data)
            },
            setDescMeta(content) {
                let metaTags = document.querySelectorAll('meta[name="description"]')

                if(metaTags.length > 0) {
                    metaTags.forEach((metaTag) => {
                        metaTag.remove()
                    })
                }

                let descTag = document.createElement('meta')

                descTag.setAttribute('name', 'description')
                descTag.setAttribute('content', content)

                document.head.appendChild(descTag)
            },
            updateOrganizationType() {
                this.$refs.panel.updateOrganizationType()
            },
            getUnreadNotices() {
                this.$refs.panel.getUnreadNotices()
            },
            toBlur() {
                document.addEventListener('keydown', (event) => {
                    if (event.ctrlKey && event.altKey && event.key === 'x') {
                        this.isBlurred = !this.isBlurred

                        this.applyBlurState()
                    }
                })
            },
            applyBlurState() {
                // Add or remove the 'blurred' class based on the current blur state
                document.querySelectorAll('.to-blur').forEach(element => {
                    if (this.isBlurred) {
                        element.classList.add('blurred')
                        this.$store.commit('setBlur', true)
                    } else {
                        element.classList.remove('blurred')
                        this.$store.commit('setBlur', false)
                    }
                })

                document.querySelectorAll('.to-hide').forEach(element => {
                    if (this.isBlurred) {
                        element.classList.add('hidden')
                    } else {
                        element.classList.remove('hidden')
                    }
                })
            }
        },
        watch: {
            $route: {
                handler(to, from) {
                    if(this.$store.state.user) {
                        if(!('isCabinet' in to.meta) || !to.meta.isCabinet) return
                        
                        let settings = this.$store.state.user.settings

                        if(to.path != '/settings/profile' && (settings.phone == '' || (!settings.address_id && !settings.address) || settings.validEmailConfirm == 0)) {
                            this.$router.push('/settings/profile')

                            this.$notify({
                                title: this.$t('notify.types.warn'),
                                text: this.$t('settings.profile.notify.need-fill-profile'),
                                type: 'warn'
                            })
                        }
                    }
                    this.applyBlurState()
                }
            }
        },
        mounted() {
            this.toBlur()
        },
        updated() {
            this.applyBlurState() // Reapply the blur state after component updates (e.g., navigation)
        }
    }
    
</script>

<style>
    :root {
        --kdp-primary: #a6a0fb;
    }

    #app {
        /* display: flex;
        flex-wrap: wrap; */
        color: black;
    }

    #content {
        margin-left: 280px;
        padding: 100px 3em 2em;
        background-color: #ffffff;
        overflow-y: auto;
    }

    #app td a {
        color: #a6a0fb;
    }

    #app .link-primary {
        color: #a6a0fb !important;
        cursor: pointer;
    }

    #app .btn:active {
        color: #ffffff;
        background-color: #a6a0fb;
        border-color: #ffffff;
    }

    #app .btn-secondary {
        color: #ffffff;
        background-color: #a6a0fb;
    }

    #app .btn-secondary:hover {
        background-color: #cac6ff;
    }

    #app .btn-secondary:focus {
        background-color: #cac6ff;
        outline: 3px solid #eeeeee;
        border-radius: 4px;
    }

    #app .btn-primary {
        background-color: #3a3077;
    }

    #app .btn-primary:hover {
        background-color: #7d6ce0;
    }

    #app .btn-primary:focus {
        background-color: #7d6ce0;
        outline: 3px solid #cac6ff;
        border-radius: 4px;
    }
    
    #app .btn-primary:active {
        background-color: #6c56ea;
    }

    #app .btn-outline-primary {
        border-color: #867ac9;
        color: #6c56ea;
        border-radius: 4px;
    }

    #app .btn-outline-primary:hover {
        background-color: #867ac9;
        color: #e3e1ff;
    }
    #app .btn-outline-primary:focus {
        background-color: #867ac9;
        color: #e3e1ff;
        outline: 4px solid #6c56ea;
        border-radius: 4px;
    }

    #app .btn-outline-secondary {
        color: #a6a0fb;
        border-color: #eeeeee;
    }

    #app .btn-outline-secondary:hover {
        border-color: #a6a0fb;
    }

    #app .btn-link:hover,
    #app .btn-link:focus {
        outline: 2px solid #cac6ff;
    }

    #app .contacts-link {
        color: #3a3077;
    }

    #app .contacts-link:hover {
        color: #7d6ce0;
    }

    #app .form-control:focus,
    #app .form-select:focus {
        border-color: #a6a0fb;
        box-shadow: inset 0px 0px 0px 1px #a6a0fb;
    }

    #app .text-primary {
        color: #3a3077;
    }

    #app .buttonBack{
        color: #a6a0fb;
        background: none;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 1.5rem;
        margin: .75rem;
        transition: 250ms;
        -moz-transition: 250ms;
        -o-transition: 250ms;
        -webkit-transition: 250ms;
    }

    #app .buttonBack:hover {
        color: #000000;
        background: none;
    }

    /* breadcrumbs */

    #app .am-breadcrumbs {
        margin: 0 0 1rem;
    }

    #app .am-breadcrumbs__link {
        color: #888888;
    }

    #app .am-breadcrumbs__link:not(.am-breadcrumbs__link_current):hover {
        color: #a6a0fb;
    }

    #app .bi-check-circle-fill {
        color: green;
    }
    
    #app .bi-question-circle-fill {
        color: rgb(255, 198, 41);
    }

    #app .bi-x-circle-fill {
        color: #dc3545;
    }

    #app .modal.fade {
        background: rgba(0, 0, 0, 0.25);
    }

    #app .modal-backdrop.fade {
        opacity: 0;
    }

    #app .my-notification {
        background-color: rgba(255, 255, 255, 0.85);
        color: #000000;
        border: 3px solid #a6a0fb;
        border-radius: 10px;
        padding: 1rem;
        margin: 1rem;
    }

    #app .template-body-text p {
        margin: 0;
        text-indent: 3em;
    }

    #app .text-bg-warning,
    .text-bg-info {
        color: #fff !important;
    }

    /* loading */

    #app .btn-loading {
        position: relative;
        overflow: hidden;
    }

    #app .btn-loading::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba(166, 160, 251, 0.9);
        z-index: 1;
    }

    #app .btn-loading::after {
        content: '\F116';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        color: #3a3077;
        font-family: bootstrap-icons;
        font-size: 1.5rem;
        animation: loading 1s linear 0s infinite;
        z-index: 2;
    }

    #app .blurred {
        filter: blur(5px);
        transition: filter 0.01s ease-in-out;
    }

    #app .blurred + .select2-container .select2-dropdown {
        filter: blur(5px);
    }

    /* For hiding instead of blurring */
    #app .hidden {
        display: none;
    }

    .loading::after {
        content: '\F116';
        display: inline-block;
        margin: -.56rem .5rem;
        color: #a6a0fb;
        font-family: bootstrap-icons;
        font-size: 1.5rem;
        animation: loading 1s linear 0s infinite;
    }

    .modal-footer .loading::after {
        font-size: 1em;
    }

    @keyframes loading {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    @media (max-width: 991px) {
        #content {
            margin-left: 0;
            padding: 2em 2em 70px;
        }
    }

    @media (max-width: 576px) {
        .my-notification {
        transform: scale(0.7)
    }
    }
</style>