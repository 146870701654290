<template>
    <div id="content" class="col">
        <div class="row">
            <div class="col-auto">
                <button type="button" class="btn btn-sm btn-primary" @click="showSearchModal">
                    {{ $t('claims.add.add-recipient') }}
                </button>
            </div>
        </div>
        <div class="my-3">
            <DataList
                ref="DataList"
                :columns="columns"
                :data="list"
                :current-page="currentPage"
                :per-page="perPage"
                :sort-by="sortBy"
                :sort-type="sortType"
                :total="totalItems"
                @button-action="handleButtonAction"
                @row-clicked="handleRowClicked"
                @updated="update"
                @search="handleSearch"
                :toBlur="true"
            />
        </div>
        <RecipientCardModal ref="recipientMdl" :recipient="recipient" @added="recipientAdded" />
        <SearchRecipientModal ref="searchRecipientModal" @recipientAdded="recipientAdded($event)" />
    </div>
</template>

<script>
    import DataList from '../cabinet/DataList.vue'
    import AddRecipientModal from './AddRecipientModal.vue'
    import RecipientCardModal from './Modal.vue'
    import SearchRecipientModal from './modals/SearchRecipientModal.vue'

    export default {
        data: () => ({
            list: [],
            loading: false,
            currentPage: 1,
            perPage: 5,
            sortBy: 'id',
            sortType: 'desc',
            totalItems: 0,
            search: '',
            searchFields: ['title', 'id_no', 'email', 'phone', 'address'],
            recipient: {}
        }),
        computed: {
            columns: {
                get() {
                    return [
                        { value: "title", text: this.$t('recipients.all.columns.title'), sortable: true },
                        { value: "id_no", text: this.$t('recipients.all.columns.id_no'), sortable: true },
                        { value: "email", text: this.$t('recipients.all.columns.email'), sortable: true },
                        { value: "phone", text: this.$t('recipients.all.columns.phone'), sortable: true },
                        { value: "address", text: this.$t('recipients.all.columns.address'), sortable: true },
                        { value: "router", text: this.$t('recipients.all.columns.actions') }
                    ]
                }
            }
        },
        components: {
            DataList,
            AddRecipientModal,
            RecipientCardModal,
            SearchRecipientModal
        },
        watch: {
            loading: {
                handler(val) {
                    this.$refs.DataList.loading = val
                }
            }
        },
        created() {
            this.perPage = this.$libs.pagination.itemsPerPage
        },
        mounted() {
            // set page title
            this.$emit('setPageTitle', this.$t('recipients.all.title'))

            this.getAll()
        },
        methods: {
            langChange() {
                this.$emit('setPageTitle', this.$t('recipients.all.title'))
            },
            update(options) {
                let { page = 0, rowsPerPage = 0, sortBy = 'id', sortType = 'desc' } = options

                if(page) this.currentPage = page
                if(rowsPerPage) this.perPage = rowsPerPage
                if(sortBy) this.sortBy = sortBy
                if(sortType) this.sortType = sortType

                this.getAll()
            },
            handleSearch(val) {
                this.search = val

                this.getAll()
            },
            async getAll() {
                if(this.loading) return
                this.loading = true

                this.list = []

                let url = process.env.VUE_APP_API_URL + '/recipients'

                let search = {}
                if(this.search) {
                    for(let i in this.searchFields) {
                        search[this.searchFields[i]] = this.search
                    }
                }

                await axios.get(url, {
                    params: {
                        page: this.currentPage,
                        limit: this.perPage,
                        sortBy: this.sortBy,
                        sortType: this.sortType,
                        search: search
                    },
                    withCredentials: true
                })
                .then((response) => {
                    let r = response.data
                    
                    if(r.result === 'SUCCESS') {
                        this.list = r.data
                        this.totalItems = r.total

                        for(let i in this.list) {
                            this.list[i].class = 'row-regular'
                            // this.list[i].class = (this.list[i]._edit ?? false) ? 'row-regular' : 'row-disabled'

                            if(Object.keys(this.recipient).length && this.recipient.id == this.list[i].id) {
                                this.recipient = this.list[i]
                            }
                        }
                    } else {
                        this.$notify({
                            title: this.$t('notify.types.error'),
                            text: this.$t('recipients.all.notify.error'),
                            type: 'error'
                        })
                    }
                })

                this.loading = false
            },
            handleRowClicked(item) {
                if(this.loading) return
                this.recipient = {}

                // if(item._edit ?? false) {
                    // this.showEditModal(item._edit)
                // } else {
                    this.recipient = item
                    this.recipientCardModal()
                // }
            },
            addRecipientModal() {         
                this.$refs.recipientModal.clearFields()

                this.$store.commit('modalShow', { id: 'addRecipientModal'})

                this.clearFields()
            },
            recipientAdded() {
                this.getAll()
            },
            showEditModal(data) {
                this.$refs.searchRecipientModal.editRecipient(data)
            },
            recipientCardModal() {
                this.$store.commit('modalShow', { id: 'recipientCardModal' })
            },
            showSearchModal() {
                this.$refs.searchRecipientModal.reset()
                this.$store.commit('modalShow', { id: 'searchRecipientModal'})
            },
            clearFields() {
                this.$refs.recipientModal.clearFields()
            }
        }
    }
</script>