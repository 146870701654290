<template>
    <div class="modal fade" :id="modalId" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="modalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg">
            <div class="modal-content p-3 text-bg-light">
                <div class="modal-header">
                    <h3 class="mb-0">{{ title }}</h3>
                </div>
                <div class="modal-body">
                    <img src="" alt="" class="preview-src" ref="claimPreview">
                </div>
                <div class="modal-footer">
                    <a :href="'tel:+'+senderPhoneNumber" target="_blank" class="btn btn-secondary">{{ $t('claim.preview.call') }}</a>
                    <a :href="'https://wa.me/'+senderPhoneNumber" target="_blank" class="btn btn-secondary">{{ $t('claim.preview.whatsapp') }}</a>
                    <button class="btn btn-primary" data-bs-dismiss="modal" @click="handleClose">{{ $t('close') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'ClaimPreviewModal',
    data: () => ({
        modalId: 'claimPreviewModal',
        claim: {},
        slug: '',
        senderPhoneNumber: ''
    }),
    computed: {
        title: {
            get() {
                return this.$t('claim.title') + ' #' + this.slug
            }
        }
    },
    mounted() {
        this.slug = this.$libs.cookies.getCookie('claimPreview') || (this.claim.slug ?? '')
        this.getClaimPreview()
    },
    methods: {
        async getClaimPreview() {
            if(!this.slug) {
                this.handleClose()
                return
            }

            let r = await this.$api.getClaimPreview(this.slug)

            if(r.result == 'ERROR') {
                this.handleClose()
                return
            }

            this.claim = r.data

            this.setImage(this.claim?.previewSrc ?? '')

            if(this.slug ?? '') {
                this.read()
            }

            this.$libs.cookies.setCookie('claimPreview', '')

            await this.getSenderPhone()
        },
        async read() {
            await this.$api.readClaim(this.slug)
        },
        setImage(src) {
            this.$refs.claimPreview.src = src
        },
        calculateRatio() {
            const w = this.$refs.claimPreview.style.width
            this.$refs.claimPreview.style.height = Math.round(w * 1.41)
        },
        async getSenderPhone() {
            let url = process.env.VUE_APP_API_URL + '/recipients/sender-phone'
            
            await axios.get(url, {
                params: {
                    senderId: this.claim.sender
                },
                withCredentials: true
            }).then((response) => {
                this.senderPhoneNumber = response.data
            })
        },
        refresh() {
            this.slug = this.$libs.cookies.getCookie('claimPreview') || (this.claim.slug ?? '')
            this.getClaimPreview()
        },
        clear() {
            this.claim = {}
            this.slug = ''
            this.senderPhoneNumber = ''
        },
        handleClose() {
            this.clear()
            this.$emit('closed')
        }
    },
}
</script>

<style scoped>
.claim-preview {
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
}
.preview-src {
    width: 100%;
    height: auto;
    pointer-events: none;
}
</style>