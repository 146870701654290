<template>
    <div id="content" class="col">        
        <div class="row">
            <div class="col-md-4 mb-3">
                <div class="card card-hover has-click" @click="handleClickNewClaim">
                    <div class="card-body text-center">
                        <i class="bi bi-file-earmark-plus-fill fs-1"></i>
                        <p class="card-text">
                            {{ $t('dashboard.create-claim') }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-4 mb-3">
                <div class="card card-hover has-click" @click="handleClickInbox">
                    <div class="card-body text-center">
                        <i class="bi bi-inboxes-fill fs-1"></i>
                        <p class="card-text to-blur" :class="[isBlurred ? 'blurred' : '']">
                            <b>{{ inbox }}</b>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-4 mb-3">
                <div class="card card-hover has-click" @click="handleClickOutbox">
                    <div class="card-body text-center">
                        <i class="bi bi-send-check-fill fs-1"></i>
                        <p class="card-text to-blur" :class="[isBlurred ? 'blurred' : '']">
                            <b>{{ outbox }}</b>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-4 mb-3">
                <div class="card card-hover">
                    <div class="card-body text-center">
                        <i class="bi bi-card-checklist fs-1"></i>
                        <p class="card-text to-blur" :class="[isBlurred ? 'blurred' : '']">
                            {{ $t('dashboard.rate') }}: <b>{{ currentPlan }}</b>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-4 mb-3">
                <div class="card card-hover">
                    <div class="card-body text-center">
                        <i class="bi bi-wallet fs-1"></i>
                        <p class="card-text to-blur" :class="[isBlurred ? 'blurred' : '']">
                            {{ $t('dashboard.balance') }}: <b>{{ balance.toLocaleString('kk-KZ') }} ₸</b>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-4 mb-3">
                <div class="card card-hover has-click" @click="handleClickRates">
                    <div class="card-body text-center">
                        <i class="bi bi-card-list fs-1"></i>
                        <p class="card-text to-blur" :class="[isBlurred ? 'blurred' : '']">
                            {{ $t('dashboard.rates-and-pay') }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="stats" v-if="stats.length">
            <hr/>
            <div class="row mt-4">
                <div class="col-md-4" v-for="stat in stats">
                    <div class="card border-primary text-primary mb-4">
                        <div class="card-header text-center">
                            <b v-html="stat.title"></b>
                        </div>
                        <div class="card-body text-center to-blur" :class="[isBlurred ? 'blurred' : '']">
                            <span v-html="stat.count"></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-3 g-3 align-items-end">
                <div class="col-md-4">
                    <div class="input-group mb-3">
                        <span class="input-group-text" id="startDate-addon">
                            {{ $t('dashboard.stats.start-date-label') }}
                        </span>
                        <input
                            v-model="startDate"
                            name="startDate"
                            type="date"
                            class="form-control"
                            :placeholder="$t('dashboard.stats.start-date-label')"
                            :aria-label="$t('dashboard.stats.start-date-label')"
                            aria-describedby="endDate-addon"
                            :disabled="statsLoading"
                        />
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="input-group mb-3">
                        <span class="input-group-text" id="endDate-addon">
                            {{ $t('dashboard.stats.end-date-label') }}
                        </span>
                        <input
                            v-model="endDate"
                            name="endDate"
                            type="date"
                            class="form-control"
                            :placeholder="$t('dashboard.stats.end-date-label')"
                            :aria-label="$t('dashboard.stats.end-date-label')"
                            aria-describedby="endDate-addon"
                            :disabled="statsLoading"
                        />
                    </div>
                </div>
                <div class="col-md-4 text-center">
                    <button type="button" class="btn btn-secondary mb-3" @click="getStats" v-if="!statsLoading">{{ $t('dashboard.stats.send') }}</button>
                    <button
                        type="button"
                        class="btn btn-secondary mb-3"
                        :class="{ disabled:loading }"
                        v-else
                    >
                        <span class="loading d-flex"></span>
                        {{ text }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Input from './form/Input.vue'

export default {
    name: 'Dashboard',
    components: { Input },
    data: () => ({
        title: 'Dashboard panel',
        startDate: '',
        endDate: '',
        stats: [],
        statsLoading: false
    }),
    props: {
        inbox: {
            type: Number,
            default: 0
        },
        outbox: {
            type: Number,
            default: 0
        },
        currentPlan: {
            type: String,
            default: ''
        },
        balance: {
            type: Number,
            default: 0
        }
    },
    computed: {
        statsTitles: {
            get() {
                return {
                    freeDeliveryCount: this.$t('dashboard.stats.free-delivery-count'),
                    electronicDeliveryCount: this.$t('dashboard.stats.electronic-delivery-count'),
                    kazpostDeliveryCount: this.$t('dashboard.stats.kazpost-delivery-count'),
                    deliveredNotificationsCount: this.$t('dashboard.stats.delivered-notifications-count'),
                    paymentSchedulesCount: this.$t('dashboard.stats.payment-schedules-count'),
                    allDeliveryCount: this.$t('dashboard.stats.all-delivery-count')
                }
            }
        },
        isBlurred: {
            get() {
                return this.$store.getters.getBlur == true
            },
            set(value) {
                this.$store.commit('setBlur', value)
            },
        },
    },
    mounted() {
        // set page title
        this.$emit('setPageTitle', this.$t('dashboard.title'))
        this.$emit('updateCounters')
        this.$emit('updateBalance')

        this.getStats()
        window.addEventListener('storage', this.syncBlurState)
    },
    beforeDestroy() {
        window.removeEventListener('storage', this.syncBlurState)
    },
    methods: {
        syncBlurState(event) {
            if (event.key === 'isBlurred') {
                this.isBlurred = event.newValue === 'true';
            }
        },
        langChange() {
            this.$emit('setPageTitle', this.$t('dashboard.title'))
        },
        handleClickNewClaim() {
            this.$router.push('/claims/new')
        },
        handleClickInbox() {
            this.$router.push('/claims/inbox')
        },
        handleClickOutbox() {
            this.$router.push('/claims/outbox')
        },
        handleClickRates() {
            this.$router.push('/settings/rates')
        },
        getUsername() {
            if (this.$store.state.user ?? false)
                return this.$store.state.user.login ?? 'Пользователь'
            return ''
        },
        getWelcomeMessage() {
            if (this.$t('dashboard.desc')) {
                return this.$t('dashboard.desc', { username: this.getUsername() })
            }
            return `Добро пожаловать ${this.getUsername()}!`
        },
        async getStats() {
            if(this.statsLoading) return
            this.statsLoading = true

            let r = await this.$api.getStats(this.startDate, this.endDate)

            this.statsLoading = false

            if(r.result == 'SUCCESS') {
                this.stats = r.data

                for(let i in this.stats) {
                    this.stats[i].title = this.statsTitles[this.stats[i].title] ?? this.stats[i].title
                }
            }
        },
        updateInfo(info) {
            this.inbox = info.inbox ?? 10
            this.outbox = info.outbox ?? 10
            this.currentPlan = info.plan?.name ?? ''
            if(process.env.VUE_APP_DEBUG) console.log('updateInfo', info)
        },
        updateTitle() {
            
        }
    }
}
</script>

<style scoped>
    hr {
        color: #8f82d8;
    }

    .text-primary {
        color: #8f82d8 !important;
    }

    .card-hover {
        color: #8f82d8;
        transition: .25s;
        -o-transition: .25s;
        -moz-transition: .25s;
        -webkit-transition: .25s;
    }

    .card-hover:hover {
        color: #ffffff;
        background-color: #8f82d8;
    }

    .card-hover.has-click {
        cursor: pointer;
    }

    .stats .input-group-text {
        background-color: #efefef;
    }

    .stats .percent {
        color: #c7c3e5;
    }

    .charts-css {
        height: 200px;
        margin: .5rem 0;
        --color: #c7c3e5;
    }

    .charts-css caption {
        margin: 0 0 1rem;
        text-align: center;
    }

    .charts-css th {
        color: #8f82d8;
        font-weight: 400;
    }

    .charts-css.column td {
        border-radius: 5px 5px 0 0;
        border: 1px solid #8f82d8;
        transition: .5s;
    }

    .charts-css.column td:hover {
        --color: #8f82d8;
    }

    .charts-css tbody {
        overflow-x: hidden; /* remove this to see how it works */
        overflow-y: hidden; /* remove this to see how it works */
    }
    .charts-css tbody th {
        background-color: #fff;
        z-index: 1;
    }
    .charts-css tbody td {
        animation: moving-bars 5s linear;
    }
    @keyframes moving-bars {
        0%  { transform: translateY( 100% ); }
        15% { transform: translateY( 0 ); }
    }

</style>