<template>
    <div class="shadow rounded px-0">
        <div>
            <div class="row" v-if="enableSearch || hasFilter">
                <div class="col-md">
                    <div class="table-filter" id="table-filter" v-if="hasFilter">
                        <Select
                            v-for="k in filterKeys"
                            v-model="filtered[k]"
                            :name="'filter-' + k"
                            :placeholder="translatedFilter[k] ?? translatedFilter.unknown"
                            :options="filter[k]"
                            :search="false"
                            :parent="'#table-filter'"
                            wrap-class="select-group-sm"
                            @change="handleFilterChange"
                        />
                    </div>
                </div>
                <div class="col-md-4" v-if="enableSearch">
                    <div class="table-search">
                        <input
                            type="text"
                            class="form-control form-control-sm"
                            v-model="searchValue"
                            :placeholder="$t('data-list.search')"
                            @input="handleSearch"
                        >
                    </div>
                </div>
            </div>
            <EasyDataTable
                v-model:server-options="serverOptions"
                :loading="loading"
                :headers="columns"
                :server-items-length="total"
                :items="data"
                :body-row-class-name="handleRowClass"
                :current-page="currentPage"
                :empty-message="$t('data-list.empty-message')"
                :rows-of-page-separator-message="$t('data-list.rows-of-page-separator-message')"
                :rows-items="[perPage]"
                :rows-per-page="perPage"
                :rows-per-page-message="$t('data-list.rows-per-page-message')"
                @click-row="handleRowClick"
                @update-page-items="update"
                :show-index="showIndex"
            >
                <template #item-title="item">
                    <td :class="getBlurClasses(item)">
                        {{ item.title }}
                    </td>
                </template>
                <template #item-lastName="item">
                    <td :class="getBlurClasses(item)">
                        {{ item.lastName }}
                    </td>
                </template>
                <template #item-company_name="item">
                    <td :class="getBlurClasses(item)">
                        {{ item.company_name }}
                    </td>
                </template>
                <template #item-company_id_num="item">
                    <td :class="getBlurClasses(item)">
                        {{ item.company_id_num }}
                    </td>
                </template>
                <template #item-recipient="item">
                    <td :class="getBlurClasses(item)">
                        {{ item.recipient }}
                    </td>
                </template>
                <template #item-senderUsername="item">
                    <td :class="getBlurClasses(item)">
                        {{ item.senderUsername }}
                    </td>
                </template>
                <template #item-id_no="item">
                    <td :class="getBlurClasses(item)">
                        {{ item.id_no }}
                    </td>
                </template>
                <template #item-email="item">
                    <td :class="getBlurClasses(item)">
                        <div v-if="item.contacts && item.contacts.emails && item.contacts.emails.length > 1">
                            {{ item.contacts.emails[0] }} {{ $t('recipients.all.row.more', { count: item.contacts.emails.length - 1 }) }}
                        </div>
                        <div v-else-if="item.email!=''">
                            {{ item.email }}
                        </div>
                        <div v-else>
                            {{ $t('recipient.recipient-card.empty') }}
                        </div>
                    </td>
                </template>
                <template #item-phone="item">
                    <td :class="getBlurClasses(item)">
                        <div v-if="item.contacts && item.contacts.phones && item.contacts.phones.length > 1">
                            {{ item.contacts.phones[0] }} {{ $t('recipients.all.row.more', { count: item.contacts.phones.length - 1 }) }}
                        </div>
                        <div v-else-if="item.phone!=''">
                            {{ item.phone }}
                        </div>
                        <div v-else>
                            {{ $t('recipient.recipient-card.empty') }}
                        </div>
                    </td>
                </template>
                <template #item-address="item">
                    <td :class="getBlurClasses(item)">
                        <div v-if="item.contacts && item.contacts.addresses && item.contacts.addresses.length > 1">
                            {{ shortAddress(item.address) }} {{ $t('recipients.all.row.more', { count: item.contacts.addresses.length - 1 }) }}
                        </div>
                        <div v-else-if="item.address!=''">
                            {{ item.address }}
                        </div>
                        <div v-else>
                            {{ $t('recipient.recipient-card.empty') }}
                        </div>
                    </td>
                </template>
                <template #item-type="{ type }">
                    <div v-html="type"></div>
                </template>
                <template #item-status="{ status }">
                    <div v-html="status"></div>
                </template>
                <template #item-buttons="item">
                    <div class="operation-wrapper">
                        <button
                            type="button"
                            class="btn btn-sm"
                            v-for="btn in item.buttons"
                            v-html="btn.text"
                            :class="btn.class ?? 'btn-primary'"
                            @click="$emit('buttonAction', btn.action, btn.link ?? false, item)"
                            @click.native="stopPropagation"
                        >
                        </button>
                    </div>
                </template>
                <template #item-router="item">
                    <div class="operation-wrapper">
                        <router-link
                            class="btn btn-primary btn-sm"
                            :to="{ path: item.router.path, query: item.router.query }"
                            @click.native="stopPropagation"
                        >
                        <i class="bi bi-send-fill px-2"></i>
                        </router-link>
                    </div>
                </template>
            </EasyDataTable>
        </div>
    </div>
</template>

<script>
    import Select from '../form/Select2.vue'

    export default {
        name: 'DataList',
        components: { Select },
        data: () => ({
            loading: false,
            searchValue: '',
            searchTimer: null,
            serverOptions: {
                page: 1,
                rowsPerPage: 1,
                sortBy: '',
                sortType: ''
            },
            filtered: {},
        }),
        props: {
            columns: {
                type: Array,
                default: []
            },
            filter: {
                type: Object,
                default: {}
            },
            enableSearch: {
                type: Boolean,
                default: true
            },
            handleSearchTimeout: {
                type: Number,
                default: 1000
            },
            data: {
                type: Array,
                default: []
            },
            rowsPerPage: {
                type: Number,
                default: 25
            },
            currentPage: {
                type: Number,
                default: 1
            },
            perPage: {
                type: Number,
                default: 1
            },
            total: {
                type: Number,
                default: 0
            },
            showIndex: {
                type: Boolean,
                default: false
            },
            toBlur: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            filterKeys() {
                if(!this.hasFilter()) return []

                return Object.keys(this.filter)
            },
            preparedFilter() {
                let filter = {}
                for(let i in this.filter) {
                    for(let j in this.filter[i]) {
                        if(this.filter[i][j]['text']) {
                            this.filter[i][j]['text'] = this.filter[i][j]['locale'] ?? `${this.filter[i][j]['id']}`
                        }
                    }
                }

                return this.filter
            },
            translatedFilter() {
                return {
                    status: this.$t('data-list.filter.status'),
                    paid_delivery: this.$t('data-list.filter.paid_delivery'),
                    unknown: this.$t('data-list.filter.unknown')
                }
            },
            isBlurred: {
                get() {
                    return this.$store.getters.getBlur == true
                },
                set(value) {
                    this.$store.commit('setBlur', value)
                },
            },
        },
        created() {
            this.serverOptions.rowsPerPage = this.perPage
        },
        mounted() {
            window.addEventListener('storage', this.syncBlurState)
        },
        beforeDestroy() {
            window.removeEventListener('storage', this.syncBlurState)
        },
        watch: {
            serverOptions: {
                handler(options) {
                    this.$emit('updated', {
                        page: options.page,
                        rowsPerPage: this.perPage,
                        sortBy: options.sortBy,
                        sortType: options.sortType
                    })
                }
            }
        },
        methods: {
            getBlurClasses() {
                return [
                    this.toBlur ? 'to-blur' : '',
                    this.isBlurred && this.toBlur ? 'blurred' : ''
                ];
            },
            syncBlurState(event) {
                if (event.key === 'isBlurred') {
                    
                    this.isBlurred = event.newValue === 'true'
                }
            },
            hasFilter() {
                return !!Object.keys(this.filter).length
            },
            handleFilterChange() {
                this.$emit('filtered', this.filtered)
            },
            handleSearch() {
                clearTimeout(this.searchTimer)

                this.searchTimer = setTimeout(() => {
                    this.$emit('search', this.searchValue)
                }, this.handleSearchTimeout)
            },
            handleRowClick(item) {
                // if row disabled, disable click
                if((item.class ?? false) === 'row-disabled') return

                this.$emit('rowClicked', item)
            },
            handleRowClass(item) {
                if(item.class ?? false) return item.class
            },
            stopPropagation(event) {
                event.stopPropagation()
            },
            shortAddress(address) {
                return address.length > 60 ? address.substring(0, 60) + '...' : address;
            }
        }
    }

</script>

<style scoped>

@import 'vue3-easy-data-table/dist/style.css';

.select2-selection.select2-selection--single {
    padding: .175em;
}

.table-filter {
    display: flex;
    gap: 1em;
    margin: 1em;
    font-size: .85em;
}

.table-filter > * {
    min-width: 200px;
    max-width: 100%;
}

.table-search {
    display: flex;
    align-items: center;
    margin: 1em 1em 1rem;
    font-size: .85em;
}

.table-search span {
    margin-right: 1em;
}

</style>

<style>

.row-regular {
    cursor: pointer;
}

.row-disabled td {
    background-color: var(--easy-table-body-row-hover-background-color) !important;
    color: var(--easy-table-body-row-hover-font-color) !important;
}

.withdrawn-badge {
    background-color: #F94D00;
}

#content .vue3-easy-data-table__footer .pagination__rows-per-page {
    display: none;
}

#content .loading-entity .lds-ring div {
    border-color: #a6a0fb transparent transparent transparent;
}

</style>