<template>
    <div id="content" class="col" v-show="mounting">
        <div class="shadow p-5 m-3 text-center">
            <span class="loading"></span>
        </div>
    </div>
    <div id="content" class="col" v-show="!mounting">
        <AmBreadcrumbs
            :showCurrentCrumb="true"
        />
        <div class="row">
            <div class="col-md-12">
                <DataList
                    ref='inboxNoticesDatalist'
                    :columns="columns"
                    :data="outbox"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :sort-by="sortBy"
                    :sort-type="sortType"
                    :total="totalItems"
                    :enableSearch="false"
                    @row-clicked="noticeClicked($event)"
                    @updated="update"
                    @buttonAction="handleButtonAction"
                    :toBlur="true"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import DataList from '../cabinet/DataList.vue'

    export default {
        data:() => ({
            outbox: [],
            totalItems: 0,
            currentPage: 1,
            perPage: 5,
            sortBy: 'created',
            sortType: 'desc',
            mounting: true,
            loading: false
        }),
        computed: {
            columns: {
                get() {
                    let cols = [
                        { value: 'address', text: 'Адрес' },
                        { value: 'amount_owed', text: 'Задолженность' },
                        { value: 'status', text: 'Статус' },
                        // { value: 'buttons', text: 'Действия' }
                    ]
                    return cols
                }
            }
        },
        components: {
            DataList
        },
        watch: {
            loading: {
                handler(val) {
                    this.$refs.inboxNoticesDatalist.loading = val
                }
            }
        },
        created() {
            this.perPage = this.$libs.pagination.itemsPerPage
        },
        mounted() {
            this.checkOsi()
            this.$emit('setPageTitle', this.$t('debt-notice.outbox.title'))

            this.getOutboxNotices()
        },
        methods: {
            langChange() {
                this.$emit('setPageTitle', this.$t('debt-notice.outbox.title'))
            },
            async update(options) {
                let { page = 0, rowsPerPage = 0, sortBy = 'created', sortType = 'desc' } = options

                if(page) this.currentPage = page
                if(rowsPerPage) this.perPage = rowsPerPage
                if(sortBy) this.sortBy = sortBy
                if(sortType) this.sortType = sortType

                await this.getOutboxNotices()
            },
            async checkOsi() {
                let isOsi = await this.$api.checkOsiStatus()
                if(!isOsi) {
                    this.$router.replace('/dashboard')
                } else{
                    this.mounting = false
                }
            },
            async getOutboxNotices() {
                this.loading = true
                let url = process.env.VUE_APP_API_URL + '/debt-notice/outbox'

                await axios.get(url, {
                    params: {
                        page: this.currentPage,
                        limit: this.perPage,
                        sortBy: this.sortBy,
                        sortType: this.sortType
                    },
                    withCredentials: true
                }).then(response => {
                    this.outbox = []
                    let r = response.data

                    if(r.result === 'SUCCESS') {
                        this.outbox = r.data
                        this.totalItems = r.total
                    }
                })

                this.loading = false
            },
            noticeClicked(data) {
                this.$router.push(data.link)
            },
            async handleButtonAction(action = '', link = '', item = '') {
                if(!item?.recipient) return
                if(!item?.address_id) return

                let r = await this.addRelation(this.$store.state.user.id, item.recipient)

                await this.$api.addAddressVisibility(item.address_id, this.$store.state.user.id)

                if(!r) return

                this.$router.push(link)
            },
            async addRelation(userId, recipientId) {
                let data = {
                    userId: userId,
                    recipientId: recipientId
                }
                
                let url = process.env.VUE_APP_API_URL + '/recipients/relations'

                let result = await axios.post(url, data, {
                    withCredentials: true
                })
                .then((response) => {
                    let r = response.data
                    if(r.result === 'SUCCESS') {
                        return r.data
                    } else {
                        return false
                    }
                })

                return result
            }
        }
    }
</script>