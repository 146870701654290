<template>
    <div class="modal fade" :id="id" aria-labelledby="modalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content text-bg-light">
                <div class="modal-header">
                    {{ $t('recipient.title') }}
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body px-5 overflow-x-auto">
                    <table class="table table-striped">
                        <tbody>
                            <tr>
                                <td>{{ recipientType == 'company' ? $t('recipient.recipient-card.bin') : $t('recipient.recipient-card.iin') }}</td>
                                <td class="to-blur">{{ id_no }}</td>
                                <td></td>
                            </tr>
                            <tr v-if="recipientType==='person'">
                                <td>{{ $t('recipient.recipient-card.lastName') }}</td>
                                <td class="to-blur">{{ lastName }}</td>
                                <td></td>
                            </tr>
                            <tr v-if="recipientType==='person'">
                                <td>{{ $t('recipient.recipient-card.firstName') }}</td>
                                <td class="to-blur">{{ firstName }}</td>
                                <td></td>
                            </tr>
                            <tr v-if="recipientType==='person'">
                                <td>{{ $t('recipient.recipient-card.patronymic') }}</td>
                                <td class="to-blur">{{ patronymic }}</td>
                                <td></td>
                            </tr>
                            <tr v-if="recipientType==='company'">
                                <td>{{ $t('recipient.recipient-card.companyName') }}</td>
                                <td class="to-blur">{{ companyName }}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>{{ $t('recipient.recipient-card.email') }} ({{ email.length }})</td>
                                <td>
                                    <div v-if="email.length > 0 && !showAllEmails">
                                        <div v-for="(e, index) in email.slice(0, 3)" :key="index" class="to-blur">
                                            {{ e }}
                                        </div>
                                        <a v-if="email.length > 3" href="#" @click.prevent="showAllEmails = true">{{ $t('recipient.recipient-card.show-more') }}</a>
                                    </div>
                                    <div v-else-if="showAllEmails"  class="to-blur">
                                        <div v-for="(e, index) in email" :key="index">
                                            {{ e }}
                                        </div>
                                    </div>
                                    <div v-else>
                                        {{ $t('recipient.recipient-card.empty') }}
                                    </div>
                                </td>
                                <td>
                                    <a class="btn btn-secondary" href="#" @click="addContactModal('email', recipient.id)">{{ $t('claims.add.add-email') }}</a>
                                </td>
                            </tr>
                            <tr>
                                <td>{{ $t('recipient.recipient-card.phone') }} ({{ phone.length }})</td>
                                <td>
                                    <div v-if="phone.length > 0 && !showAllPhones">
                                        <div v-for="(p, index) in phone.slice(0, 3)" :key="index" class="to-blur">
                                            {{ p }}
                                        </div>
                                        <a v-if="phone.length > 3" href="#" @click.prevent="showAllPhones = true">{{ $t('recipient.recipient-card.show-more') }}</a>
                                    </div>
                                    <div v-else-if="showAllPhones">
                                        <div v-for="(p, index) in phone" :key="index" class="to-blur">
                                            {{ p }}
                                        </div>
                                    </div>
                                    <div v-else>
                                        {{ $t('recipient.recipient-card.empty') }}
                                    </div>
                                </td>
                                <td>
                                    <a class="btn btn-secondary" href="#" @click="addContactModal('phone', recipient.id)">{{ $t('claims.add.add-phone') }}</a>
                                </td>
                            </tr>
                            <tr>
                                <td>{{ $t('recipient.recipient-card.addresses') }} ({{ address.length }})</td>
                                <td>
                                    <div v-if="address.length > 0 && !showAllAddresses">
                                        <div v-for="(a, index) in address.slice(0, 3)" :key="index" class="to-blur">
                                            {{ a }}
                                        </div>
                                        <a v-if="address.length > 3" href="#" @click.prevent="showAllAddresses = true">{{ $t('recipient.recipient-card.show-more') }}</a>
                                    </div>
                                    <div v-else-if="showAllAddresses">
                                        <div v-for="(a, index) in address" :key="index" class="to-blur">
                                            {{ a }}
                                        </div>
                                    </div>
                                    <div v-else>
                                        {{ $t('recipient.recipient-card.empty') }}
                                    </div>
                                    <div class="accordion" id="accordionAddress" v-if="false">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseAddress" aria-expanded="false" aria-controls="collapseAddress">
                                                    {{ $t('recipient.recipient-card.addresses') }} ({{ address.length }})
                                                </button>
                                            </h2>
                                            <div id="collapseAddress" class="accordion-collapse collapse" data-bs-parent="#accordionAddress">
                                                <div class="accordion-body">
                                                    <table class="table table-sm table-borderless">
                                                        <tbody>
                                                            <tr v-for="a in address">
                                                                <td>
                                                                    {{ a }}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <a class="btn btn-secondary" ref="addAddressBtn" href="#" @click="addContactModal('address', recipient.id)">{{ $t('claims.add.add-address') }}</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="modal-footer p-3">

                </div>
            </div>
        </div>
    </div>
    <AddRecipientContactsModal
        ref="addRecipientContactsModal"
        @added="added"
    />
</template>

<script>

    import { shortenCompanyName } from '../../../libs/text';
    import AddRecipientContactsModal from './modals/AddRecipientContactsModal.vue'

    export default {
        name: 'recipientCardModal',
        data: () => ({
            id: 'recipientCardModal',
            contactModalType: '',
            showAllEmails: false,
            showAllPhones: false,
            showAllAddresses: false
        }),
        props: {
            recipient: {
                type: Object,
                default: {}
            }
        },
        components: {
            AddRecipientContactsModal
        },
        computed: {
            recipientType: {
                get() {
                    if(this.recipient.company_name) return 'company'
                    if(!this.recipient.company_name) return 'person'
                }
            },
            firstName: {
                get() {
                    if(this.recipient._edit) return this.recipient._edit.firstName
                    return this.recipient.firstName
                }
            },
            lastName: {
                get() {
                    if(this.recipient._edit) return this.recipient._edit.lastName
                    return this.recipient.lastName
                }
            },
            patronymic: {
                get() {
                    if(this.recipient._edit) return this.recipient._edit.patronymic
                    return this.recipient.patronymic
                }
            },
            companyName: {
                get() {
                    if(this.recipient._edit) return shortenCompanyName(this.recipient._edit.company_name)
                    return shortenCompanyName(this.recipient.company_name)
                }
            },
            id_no: {
                get() {
                    if(this.recipient) return this.recipient.id_no
                }
            },
            company_id_num: {
                get() {
                    if(this.recipient) return this.recipient.company_id_num
                }
            },
            email: {
                get() {
                    if(this.recipient.contacts) return this.recipient.contacts.emails
                    return []
                }
            },
            phone: {
                get() {
                    if(this.recipient.contacts) return this.recipient.contacts.phones
                    return []
                }
            },
            address: {
                get() {
                    if(this.recipient.contacts) return this.recipient.contacts.addresses
                    return []
                }
            }
        },
        methods: {
            addContactModal(type, id) {
                this.$refs.addRecipientContactsModal.setType(type, id)

                this.contactModalType = type

                this.$store.commit('modalShow', { id: 'addRecipientContactsModal' })
            },
            added(data) {
                this.$emit('added', data.recipientId)
            },
            clickAddAddress() {
                this.$refs.addAddressBtn.click()
            }
        }
    }

</script>

<style scoped>
.overflow-x-auto {
    overflow-x: auto;
}
</style>