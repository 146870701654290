<template>
    <div class="debt-text">
        <div class="card">
            <div class="card-body">
                <p class="text-end mb-3">
                    {{ $t('debt-text.recipient-address', this.language) }}: <span class="to-blur">{{ recipientAddress }}</span><br/>
                    {{ $t('debt-text.sender-username') }}: <span class="to-blur">{{ senderUsername }}</span><br/>
                    {{ $t('debt-text.sender-address', this.language) }}: <span class="to-blur">{{ senderAddress }}</span><br/>
                </p>
                <h5 class="mb-4 text-center">{{ $t('debt-notice.title').toUpperCase() }}</h5>
                
                <p v-html="processedText"></p>
                
                <p style="margin: 30px 0 60px;">
                    <b><span class="to-blur">{{ senderUsername }}</span></b>
                    <span class="d-block to-blur" v-if="representative">{{ representative }}</span>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    import { generate } from '../../../libs/generator'
    import { shortenCompanyName } from '../../../libs/text'

    export default {
        data: () => ({
            text: '',
            processedText: ''
        }),
        props: {
            address: {
                type: String,
                default: ''
            },
            senderData: {
                type: Object,
                default: {}
            },
            amount_owed: {
                type: String,
                default: ''
            },
            recipient: {
                type: Object,
                default: {}
            }
        },
        mounted() {
            window.addEventListener('storage', this.syncBlurState)
            this.generateText()
        },
        beforeDestroy() {
            window.removeEventListener('storage', this.syncBlurState)
        },
        watch: {
            amount_owed: function () {
                this.generateText()
            },
            address: function () {
                this.generateText()
            },
            senderData: function () {
                this.generateText()
            },
            processedText: function () {
                this.applyBlurState()
            }
        },
        computed: {
            recipientAddress: {
                get() {
                    if(!this.address) return this.$t('claim-text.default-recipient-address')

                    return this.address
                }
            },
            senderUsername: {
                get() {
                    if(!this.senderData?.title) return this.$t('claim-text.default-sender-username')

                    if(this.senderData.company_name) return shortenCompanyName(this.senderData.company_name)               

                    return this.senderData.title
                }
            },
            representative: {
                get() {
                    if(!this.senderData || !this.senderData.company_name) return ''
                    return this.senderData.fullName
                }
            },
            senderAddress: {
                get() {
                    if(!this.senderData || !this.senderData.address) return this.$t('claim-text.default-sender-address')
                    return this.senderData.address
                }
            },
            totalAmount: {
                get() {
                    if(!this.amount_owed) return '[СУММА ЗАДОЛЖЕННОСТИ]'

                    return this.amount_owed
                }
            },
            isBlurred: {
                get() {
                    return this.$store.getters.getBlur == true
                },
                set(value) {
                    this.$store.commit('setBlur', value)
                },
            },
        },
        methods: {
            processText() {
                // Create a DOM parser to manipulate the text
                const parser = new DOMParser()
                const doc = parser.parseFromString(this.text, "text/html")

                // Find all elements with the `to-blur` class
                const elements = doc.querySelectorAll(".to-blur")

                // Add the `blurred` class if `isBlurred` is true
                if (this.isBlurred) {
                    elements.forEach((el) => {
                    el.classList.add("blurred")
                    })
                }

                // Return the modified HTML as a string
                return doc.body.innerHTML
            },
            generateText() {
                let data = {
                    occurrence_date: this.occurrence_date,
                    amount_owed: this.amount_owed,
                    template_fields: {
                        object_address: {
                            field_value: this.recipientAddress
                        }
                    }
                }

                let text = generate('debt_notice', data, this.senderData, this.recipient)

                this.text = text

                this.processedText = this.processText()
            },
            syncBlurState(event) {
                if (event.key === 'isBlurred') {
                    
                    this.isBlurred = event.newValue === 'true'
                }
            },
            applyBlurState() {
                // Add or remove the 'blurred' class based on the current blur state
                document.querySelectorAll('.to-blur').forEach(element => {
                    if (this.isBlurred) {
                        element.classList.add('blurred')
                        localStorage.setItem('isBlurred', true)
                    } else {
                        element.classList.remove('blurred')
                        localStorage.setItem('isBlurred', false)
                    }
                })
            },
        },
        updated() {
            this.applyBlurState() // Reapply the blur state after component updates (e.g., navigation)
        }
    }
</script>

<style scoped>

.debt-text {
    max-width: 800px;
    margin: 0 auto;
    text-align: justify;
}

@media (min-width: 1024px) {
    .card-body {
        padding: 3em 4em;
    }
}

</style>