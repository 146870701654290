const { translate } = require("./locale")

const templateFields = {
    vozvrat: {
        given_amount: {
            label: 'templates.vozvrat.given_amount_label',
            type: 'number',
            field_value: '',
            validations: ['required', 'min(0)'],
            maska: "################"
        },
        agreement_date: {
            label: 'templates.vozvrat.agreement_date_label',
            type: 'date',
            field_value: '',
            validations: ['date', 'required', 'dateRecent']
        },
        return_date: {
            label: 'templates.vozvrat.return_date_label',
            type: 'date',
            field_value: '',
            validations: ['date', 'required', 'dateCompare']
        },
        amount_of_penalty: {
            label: 'templates.vozvrat.amount_of_penalty_label',
            type: 'number',
            field_value: '',
            validations: [],
            maska: "################"
        }
    },
    dogovor: {
        contract_title: {
            label: 'templates.dogovor.contract-title-label',
            type: 'text',
            field_value: '',
            validations: ['required']
        },
        contract_number: {
            label: 'templates.dogovor.contract-number-label',
            type: 'text',
            field_value: '',
            validations: ['required']
        },
        contract_date: {
            label: 'templates.dogovor.contract-date-label',
            type: 'date',
            field_value: '',
            validations: ['date', 'dateRecent', 'required']
        },
        contract_amount: {
            label: 'templates.dogovor.contract-amount-label',
            type: 'number',
            field_value: '',
            validations: ['required', 'min(0)'],
            maska: "################"
        },
        contract_penalty: {
            label: 'templates.dogovor.contract-penalty-label',
            type: 'number',
            field_value: '',
            validations: ['min(0)'],
            maska: "################"
        }
    },
    zp: {
        work_contract_number: {
            label: 'templates.zp.work-contract-number-label',
            type: 'text',
            field_value: '',
            validations: ['required']
        },
        contract_date: {
            label: 'templates.zp.contract-date-label',
            type: 'date',
            field_value: '',
            validations: ['date', 'dateCompare', 'dateRecent', 'required']
        },
        position: {
            label: 'templates.zp.position-label',
            type: 'text',
            field_value: '',
            validations: ['required']
        },
        work_start_date: {
            label: 'templates.zp.work-start-date-label',
            type: 'date',
            field_value: '',
            validations: ['date', 'dateCompare', 'dateRecent', 'required']
        }
    },
    osi: {
        object_address: {
            label: 'templates.osi.object-address-label',
            type: 'select',
            field_value: '',
            options: [],
            validations: ['required'],
            blur: true
        }
    },
    mfo: {
        mfo_contract_number: {
            label: 'templates.mfo.contract-number-label',
            type: 'text',
            field_value: '',
            validations: ['required']
        },
        mfo_contract_date: {
            label: 'templates.mfo.contract-date-label',
            type: 'date',
            field_value: '',
            validations: ['date', 'dateRecent', 'required']
        },
        credit_line_number: {
            label: 'templates.mfo.credit-line-number-label',
            type: 'text',
            field_value: '',
            validations: ['required']
        },
        credit_line_date: {
            label: 'templates.mfo.credit-line-date-label',
            type: 'date',
            field_value: '',
            validations: ['date', 'dateRecent', 'required']
        }
    },
    mfogarant: {
        borrower_name: {
            label: 'templates.mfogarant.borrower-name-label',
            type: 'text',
            field_value: '',
            validations: ['required'],
            blur: true
        },
        warranty_contract_number: {
            label: 'templates.mfogarant.warranty-contract-number-label',
            type: 'text',
            field_value: '',
            validations: ['required']
        },
        warranty_contract_date: {
            label: 'templates.mfogarant.warranty-contract-date-label',
            type: 'date',
            field_value: '',
            validations: ['date', 'dateRecent', 'required']
        },
        mfo_contract_number: {
            label: 'templates.mfo.contract-number-label',
            type: 'text',
            field_value: '',
            validations: ['required']
        },
        mfo_contract_date: {
            label: 'templates.mfo.contract-date-label',
            type: 'date',
            field_value: '',
            validations: ['date', 'dateRecent', 'required']
        },
        credit_line_number: {
            label: 'templates.mfo.credit-line-number-label',
            type: 'text',
            field_value: '',
            validations: ['required']
        },
        credit_line_date: {
            label: 'templates.mfo.credit-line-date-label',
            type: 'date',
            field_value: '',
            validations: ['date', 'dateRecent', 'required']
        }
    },
    mforazbivka: {
        mfo_contract_number: {
            label: 'templates.mfo.contract-number-label',
            type: 'text',
            field_value: '',
            validations: ['required']
        },
        mfo_contract_date: {
            label: 'templates.mfo.contract-date-label',
            type: 'date',
            field_value: '',
            validations: ['date', 'dateRecent', 'required']
        },
        credit_line_number: {
            label: 'templates.mfo.credit-line-number-label',
            type: 'text',
            field_value: '',
            validations: ['required']
        },
        credit_line_date: {
            label: 'templates.mfo.credit-line-date-label',
            type: 'date',
            field_value: '',
            validations: ['date', 'dateRecent', 'required']
        },
        compensation_amount: {
            label: 'templates.mforazbivka.compensation-label',
            type: 'number',
            field_value: '',
            validations: ['min(0)'],
            maska: "################"
        },
        mfo_penalty: {
            label: 'templates.mforazbivka.penalty-label',
            type: 'number',
            field_value: '',
            validations: ['min(0)'],
            maska: "################"
        }
    },
    zadatok: {
        amount_given: {
            label: 'templates.zadatok.amount-given-label',
            type: 'number',
            field_value: '',
            validations: ['required', 'min(0)'],
            maska: "################"
        },
        deposit_contract_number: {
            label: 'templates.zadatok.contract-number-label',
            type: 'text',
            field_value: '',
            validations: ['required']
        },
        deposit_contract_date: {
            label: 'templates.zadatok.contract-date-label',
            type: 'date',
            field_value: '',
            validations: ['date', 'dateRecent', 'required']
        },
        deposit_return_date: {
            label: 'templates.zadatok.return-date-label',
            type: 'date',
            field_value: '',
            validations: ['date', 'dateRecent', 'required']
        },
        deposit_additional_costs: {
            label: 'templates.zadatok.additional-costs-label',
            type: 'number',
            field_value: '',
            validations: ['min(0)'],
            maska: "################"
        }
    },
    vodokanal: {
        vk_contract_number: {
            label: 'templates.vodokanal.vk-contract-number-label',
            type: 'text',
            field_value: '',
            validations: ['required']
        },
        consumer_code: {
            label: 'templates.vodokanal.consumer-code-label',
            type: 'text',
            field_value: '',
            validations: ['required']
        },
        object_address: {
            label: 'templates.vodokanal.object-address-label',
            type: 'select',
            field_value: '',
            options: [],
            validations: ['required']
        }
    },
    freeform: {
        types_list: {
            label: 'templates.freeform.types-list-label',
            type: 'select',
            field_value: '',
            options: translate('templates.freeform.types'),
            validations: ['required']
        }
    }
}

module.exports = {
    templateFields
}