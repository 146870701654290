<template>
    <div class="notification-wrapper" ref="wrapper">
        <button class="notification-button" @click="handleClick">
            <i class="bi bi-bell-fill"></i>
            <span v-if="hasUnreadNotifications" class="unread-indicator bg-danger"></span>
        </button>
        <div v-show="isVisible" class="notification-dropdown">
            <div class="header">
                <h5 class="mb-0"> {{ $t('notifications.general.title') }} </h5>
                <div class="filter-controls">
                    <span>{{ $t('notifications.general.all') }}</span>
                    <label class="switch">
                        <input type="checkbox" v-model="showUnreadOnly">
                        <span class="slider round"></span>
                    </label>
                    <span>{{ $t('notifications.general.unread') }}</span>
                </div>
            </div>
            <div v-if="filteredNotifications.length > 0" class="scrollable-content">
                <router-link v-for="notification in filteredNotifications" :to="notification.notificationData.link" @click="handleClick" class="black-to-blue-link">
                    <div @mouseover="markAsRead(notification)" class="rounded notification-instance">
                        <h5>{{ notification.title }}</h5>
                        <p class="to-blur" :class="[isBlurred ? 'blurred' : '']">{{ notification.text }}</p>
                        <span v-if="notification.status == 0" class="unread-indicator-instance bg-danger"></span>
                        <p class="notification-date">{{ notification.created }}</p>
                    </div>
                </router-link>
            </div>
            <div v-else class="d-flex justify-content-center align-items-center">
                <p>{{ $t('notifications.general.empty') }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: () => ({
        isVisible: false,
        showUnreadOnly: false,
        notifications: [],
        filteredNotifications: []
    }),
    name: 'SystemNotifications',
    watch: {
            notifications: {
                immediate: true,
                handler() {
                    this.filteredNotifications = this.showUnreadOnly ? this.notifications.filter(notification => notification.status == 0) : this.notifications
                }
            },
            showUnreadOnly: {
                immediate: true,
                handler() {
                    this.filteredNotifications = this.showUnreadOnly ? this.notifications.filter(notification => notification.status == 0) : this.notifications
                }
            }
    },
    computed: {
        hasUnreadNotifications() {
            return this.notifications.some(notification => notification.status == 0)
        },
        notificationText() {
            return {
                newClaimInbox: (slug) => this.$t('notifications.text.new-claim-inbox', { slug }),
                claimAttachedPaymentReceipt: (slug) => this.$t('notifications.text.claim-attached-payment-receipt', { slug }),
                claimStatusChanged: (slug, status) => this.$t('notifications.text.claim-status-changed', { slug, status }),
                other: () => this.$t('notifications.text.default')
            }
        },
        notificationTitle() {
            return {
                newClaimInbox: () => this.$t('notifications.title.new-claim-inbox'),
                claimAttachedPaymentReceipt: () => this.$t('notifications.title.claim-attached-payment-receipt'),
                claimStatusChanged: () => this.$t('notifications.title.claim-status-changed'),
                other: () => this.$t('notifications.title.default')
            }
        },
        isBlurred: {
            get() {
                return this.$store.getters.getBlur == true
            },
            set(value) {
                this.$store.commit('setBlur', value)
            },
        },
    },
    methods: {
        syncBlurState(event) {
            if (event.key === 'isBlurred') {
                this.isBlurred = event.newValue === 'true';
            }
        },
        handleClick() {
            this.isVisible = !this.isVisible
        },
        handleClickOutside(event) {
            if (this.$refs.wrapper && !this.$refs.wrapper.contains(event.target)) {
                this.isVisible = false
            }
        },
        markAsRead(notification) {
            let url = process.env.VUE_APP_API_URL + '/system-notifications/read'

            axios.post(url, {
                id: notification.id
            },{
                withCredentials: true
            })
            .then((response) => {
                if(process.env.VUE_APP_DEBUG) console.log(response)

                let r = response.data

                if(r.result === 'SUCCESS') {
                    notification.status = 1
                } 
            })
            
        },
        setNotificationTitle(notification) {
            if (notification.event === 'newClaimInbox') {
                notification.title = this.notificationTitle[notification.event]()
            } else if (notification.event === 'claimAttachedPaymentReceipt') {
                notification.title = this.notificationTitle[notification.event]()
            } else if (notification.event === 'claimStatusChanged') {
                notification.title = this.notificationTitle[notification.event]()
            } else if (notification.event === 'other') {
                notification.title = this.notificationTitle[notification.event]()
            } else {
                notification.title = this.$t('notifications.default')
            }
        },
        setNotificationText(notification) {
            const slug = notification.notificationData.slug
            const statusKey = notification.notificationData.status
            const status = this.$t(`claim.statuses.${statusKey}`)

            if (notification.event === 'newClaimInbox') {
                notification.text = this.notificationText[notification.event](slug)
            } else if (notification.event === 'claimAttachedPaymentReceipt') {
                notification.text = this.notificationText[notification.event](slug)
            } else if (notification.event === 'claimStatusChanged') {
                notification.text = this.notificationText[notification.event](slug, status)
            } else if (notification.event === 'other') {
                notification.text = this.notificationText[notification.event]()
            } else {
                notification.text = this.$t('notifications.default')
            }
        },
        getNotifications() {
        let url = process.env.VUE_APP_API_URL + '/system-notifications/get-notifications'

        axios.get(url, {
            params: {
                userId: this.$store.state.user.id
            },
            withCredentials: true
        })
        .then((response) => {
            if(process.env.VUE_APP_DEBUG) console.log(response)

            let r = response.data

            if(r.result === 'SUCCESS' && r.data != []) {
                this.notifications = r.data
                this.notifications.forEach(notification => {
                    this.setNotificationText(notification)
                    this.setNotificationTitle(notification)
                })
            } 
        })
    },
    },
    mounted() {
        document.addEventListener('click', this.handleClickOutside)
        this.getNotifications()
        window.addEventListener('storage', this.syncBlurState)
    },
    beforeDestroy() {
        document.removeEventListener('click', this.handleClickOutside)
        window.removeEventListener('storage', this.syncBlurState)
    }
}
</script>

<style scoped>

.black-to-blue-link {
    color: black; 
    text-decoration: none; 
}

.filter-controls {
    display: flex;
    align-items: center;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    background-color: #f5f5f5;
    z-index: 1;
    padding: 20px 20px 20px 30px;
    border-bottom: 1px solid #ddd;
}

.label {
    margin: 0 5px;
}

.notification-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 24px;
    color: #29205d; 
    font-size: 20px; 
    position: relative;
    padding: 5px;
}

.notification-button:hover {
    color: #6b6981; 
}

.notification-date {
    font-size: 0.8rem;
    color: #888; 
    margin-bottom: 0;
}

.notification-dropdown {
    position: absolute;
    top: 100%; 
    right: 0;
    background-color: #f5f5f5;
    border: 1px solid #ddd; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
    z-index: 1000; 
    max-height: 80vh;
    width: 500px;
    display: flex;
    flex-direction: column;
}

.notification-instance {
    position: relative;
    padding: 0px 30px 0px 30px !important;
}

.notification-instance:first-child {
    margin-top: 0 !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}


.notification-instance:hover {
    background-color: #e0e0e0;
}

.notification-wrapper {
    position: relative;
    display: inline-block;
}

.p-3 {
    padding: 0.5rem 1rem !important;
}

.scrollable-content {
    overflow-y: auto;
    flex-grow: 1;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

.switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px;
    margin: 0 5px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.unread-indicator {
    position: absolute;
    top: 10px;
    right: 4px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.unread-indicator-instance {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: #29205d;
}

input:checked + .slider:before {
    transform: translateX(14px);
}

</style>