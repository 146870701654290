<template>
    <header class="cabinet-header">
        <div class="container-fluid">
            <div class="row justify-content-between">
                <div class="col-5">
                    <h3>{{ title }}</h3>
                </div>
                <div class="col d-flex justify-content-end align-items-center">
                    <LangSwitcher @changed="langChanged" />
                    <router-link to="/settings/profile" class="nav-item">
                        <span class="header-username text-truncate to-blur" data-bs-toggle="tooltip" :data-bs-title="username||$t('header.default-user-tooltip')">
                            {{ username }}
                        </span>
                        <i class="bi bi-person-fill mx-1"></i>
                    </router-link>
                    <router-link to="/settings/rates" class="nav-item">
                        {{ balance.toLocaleString('kk-KZ') }} ₸
                    </router-link>
                    <!-- <router-link to="/settings/rates" class="nav-item ps-1">
                        {{ bonusBalance.toLocaleString('kk-KZ') }} ₸
                    </router-link> -->
                    <SystemNotifications/>
                </div>
            </div>
        </div>
    </header>
</template>

<script>

import LangSwitcher from '../LangSwitcher.vue'
import SystemNotifications from './SystemNotifications.vue'

export default {
    name: 'Navbar',
    data: () => ({
        username: '',
        balance: 0,
        bonusBalance: 0
    }),
    props: {
        title: {
            type: String,
            default: ''
        }
    },
    components: {
        LangSwitcher,
        SystemNotifications
    },
    mounted() {
        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
        tooltipTriggerList.forEach(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
        
        this.getUsername()
        this.getBalance()
        this.getBonusBalance()
    },
    methods: {
        getUsername() {
            if(this.$store.state.user ?? false) {
                this.username = this.$store.state.user.login ?? 'Пользователь'
            }
        },
        async getBalance() {
            let url = process.env.VUE_APP_API_URL + '/accounts/my'

            await axios.get(url, {
                withCredentials: true
            }).then((response) => {
                let r = response.data

                if(r.result === 'SUCCESS') {
                    this.balance = r.data.balance !== null ? r.data.balance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : 0
                }
            })

            this.$emit('balanceChanged', this.balance)
        },
        async getBonusBalance() {
            let url = process.env.VUE_APP_API_URL + '/bonus-accounts/my'

            await axios.get(url, {
                withCredentials: true
            }).then((response) => {
                let r = response.data

                if(r.result === 'SUCCESS') {
                    this.bonusBalance = r.data.balance !== null ? r.data.balance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : 0
                }
            })
        },
        langChanged(lang) {
            this.$emit('langChanged', lang)
        }
    }
}

</script>

<style scoped>

    .cabinet-header {
        position: fixed;
        top: 0;
        left: 280px;
        right: 0;
        width: calc(100% - 280px);
        padding: 1em 2em;
        background-color: #ffffff;
        box-shadow: .125rem 0 .75rem rgb(0 0 0 / 50%);
        z-index: 3;
    }

    .header-welcome {
        margin: 0 .5em 0 2em;
    }
    
    .header-username {
        position: relative;
        display: inline-block;
        width: fit-content;
        max-width: 120px;
        overflow: hidden;
    }

    .lang-switcher {
        color: #000000;
    }

    .lang-item {
        border-left: 1px solid #000000;
    }

    .nav-item {
        display: flex;
        align-items: center;
        color: #000000;
        text-decoration: none;
    }

    .nav-item:hover {
        color: #3a3077;
        transition: .25s;
        -webkit-transition: .25s;
        -o-transition: .25s;
        -moz-transition: .25s;
    }

    @media (max-width: 991px) {
        .cabinet-header {
            display: none;
        }
    }

</style>