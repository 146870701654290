<template>
    <Form class="shadow p-3 mb-5 rounded" @submit="onSubmit">
        <div class="row align-items-center">
            <div class="col-md-12">
                <Input
                    v-model="occurrence_date"
                    name="occurrence_date"
                    type="date"
                    :label="$t('claims.add.occurrence-date')"
                    :placeholder="$t('claims.add.occurrence-date')"
                    :max="todayDate"
                    :validations="['date', 'dateCompare']"
                />
            </div>
        </div>
        <div class="row align-items-center">
            <div class="input-group mb-3">
                <input
                    v-model="shortAddress"
                    type="text"
                    class="form-control border-end-0 to-blur"
                    aria-label="Address"
                    :placeholder="$t('settings.profile.change-address')"
                    :validations="['required']"
                    readonly
                >
                <button type="button" class="btn btn-primary btn-input" @click="showAddressModal()"><i class="bi bi-search"></i></button>
            </div>
        </div>
        <!-- <div class="row align-items-center" v-if="address_recipient_id"> -->
        <div class="row align-items-center" v-if="false">
            <div class="col-md-12">
                <p>Получатель с таким адресом зарегистрирован. Хотите отправить претензию?</p>
                <button type="button" class="btn btn-secondary mb-3" @click="showRecipients">Выбрать получателя</button>
            </div>
        </div>
        <!-- <div class="row align-items-center" v-if="address_recipient_id">
            <div class="col-md-12">
                <p>Получатель с таким адресом зарегистрирован. Хотите отправить претензию?</p>
                <button type="button" class="btn btn-secondary mb-3" @click="sendClaim">Создать претензию</button>
            </div>
        </div> -->
        <div class="row align-items-center">
            <div class="col-md-12"> 
                <Input
                    v-model="amount_owed"
                    name="amount_owed"
                    type="number"
                    :label="$t('claims.add.amount-owed')"
                    :placeholder="$t('claims.add.amount-owed')"
                    :validations="['required', 'min(0)']"
                />
            </div>
        </div>
        <div class="d-flex flex-wrap align-items-center gap-2">
            <button type="submit" class="btn btn-primary" v-if="true" :class="{ disabled: btnDisabled }">{{ $t('claims.add.send-claim') }}</button>
            <button type="submit" class="btn btn-secondary" v-if="true" :class="{ disabled: btnDisabled }" @click="send = false">{{ $t('claims.add.save-claim') }}</button>
            <button type="button" class="btn btn-outline-danger" v-if="noticeId" @click="cancel">{{ $t('claims.edit.cancel') }}</button>
        </div>
    </Form>
    <AddressModal ref="debtAddressModal" :blank="true" @saved="addressOnChange($event)" />
    <DebtRecipientsModal ref="debtRecipientsModal" :addressId="addressId" @recipientClicked="sendClaim($event)" />
</template>

<script>
import { Form } from 'vee-validate'
import Input from '../form/Input.vue'
import { addZeros } from '../../../libs/text'
import AddressModal from '../settings/AddressModal.vue'
import DebtRecipientsModal from './DebtRecipientsModal.vue'

export default {
    data: () => ({
        occurrence_date: '',
        address: '',
        shortAddress: '',
        address_object: {},
        amount_owed: '',
        sender: '',
        senderData: '',
        send: true,
        address_recipient_id: false,
        addressId: false,
        loading: false
    }),
    props: {
        addressProp: {
            type: String,
            default: ''
        },
        occurrenceDate: {
            type: String,
            default: ''
        },
        amountOwed: {
            type: String,
            default: ''
        },
        addressObject: {
            type: Object,
            default: {}
        },
        noticeId: {
            type: Number,
            default: 0
        }
    },
    mounted() {
        this.occurrence_date = this.occurrenceDate || this.getTodayDate()
        this.address = this.addressProp || ''
        this.amount_owed = this.amountOwed || ''
    },
    computed: {
        btnDisabled: {
            get() {
                return !!(!this.address || !this.occurrence_date || !this.amount_owed)
            }
        }
    },
    components: {
        Form,
        Input,
        AddressModal,
        DebtRecipientsModal
    },
    watch: {
        address: function () {
            this.$emit('updateAddress', this.address)
        },
        senderData: function () {
            this.$emit('updateSender', this.senderData)
        },
        amount_owed: function () {
            this.$emit('updateAmount', this.amount_owed)
        },
        amountOwed: function () {
            this.amount_owed = this.amountOwed
        },
        addressProp: function () {
            this.address = this.addressProp
        },
        occurrenceDate: function () {
            this.occurrence_date = this.occurrenceDate
        },
        addressObject: function () {
            this.address_object = this.addressObject
            this.getAddressString()
        }
    },
    methods: {
        getTodayDate() {
            let today = new Date(),
                d = today.getDate(),
                m = today.getMonth() + 1,
                y = today.getFullYear()

            return y + '-' + addZeros(m, 2) + '-' + addZeros(d, 2)
        },
        async addressOnChange(value = '') {            
            this.address_object = value

            await this.checkAddressRecipient()

            await this.getAddressString()
        },
        async getAddressString() {
            let url = process.env.VUE_APP_API_URL + '/location/address-str'

            await axios.get(url, {
                params: {
                    address: this.address_object
                },
                withCredentials: true
            }).then(response => {
                let r = response.data

                if(r.result === 'SUCCESS') {
                    this.address = r.data.full
                    this.shortAddress = r.data.short
                } else {
                    this.$notify({
                        title: this.$t('notify.types.error'),
                        text: r.message,
                        type: 'error'
                    })
                }
            })
        },
        async checkAddressRecipient() {
            let r = await this.$api.checkAddressRecipient(this.address_object)
            
            if(r.result === 'SUCCESS') {
                if(!r.data) return

                this.address_recipient_id = r.data.recipient_id
                this.addressId = r.data.id
            } else {
                this.$notify({
                    title: this.$t('notify.types.error'),
                    text: r.message,
                    type: 'error'
                })
            }
        },
        async sendClaim(recipientId) {
            if(!recipientId) return
            if(!this.addressId) return

            let r = await this.addRelation(this.$store.state.user.id, recipientId)

            await this.$api.addAddressVisibility(this.addressId, this.$store.state.user.id)

            if(!r) return

            this.$router.push(`/claims/new?recipient=${recipientId}&essence=osi&amount_owed=${this.amount_owed}`)
        },
        onSubmit() {
            let dateTs = new Date(this.occurrence_date)

            let body = {
                id: this.noticeId,
                address: this.address_object,
                date: dateTs,
                amount_owed: this.amount_owed,
                sendNow: this.send
            }

            this.$emit('createNotice', body)
        },
        showAddressModal() {
            this.$store.commit('modalShow', { id: 'addressModal' })
        },
        async showRecipients() {
            await this.$refs.debtRecipientsModal.getRecipients()
            this.$store.commit('modalShow', { id: 'debtRecipientsModal' })
        },
        async addRelation(userId, recipientId) {
            let data = {
                userId: userId,
                recipientId: recipientId
            }
            
            let url = process.env.VUE_APP_API_URL + '/recipients/relations'

            let result = await axios.post(url, data, {
                withCredentials: true
            })
            .then((response) => {
                let r = response.data
                if(r.result === 'SUCCESS') {
                    return r.data
                } else {
                    return false
                }
            })

            return result
        },
        cancel() {
            this.$router.go(-1)
        }
    }
}

</script>