<template>
    <div id="content" class="col" v-if="mounting">
        <div class="shadow p-5 m-3 text-center">
            <span class="loading"></span>
        </div>
    </div>
    <div id="content" class="col" v-if="!mounting">
        <AmBreadcrumbs
            :showCurrentCrumb="true"
        />
        <div class="row">
            <div class="col-md-12">
                <DataList
                    ref='inboxNoticesDatalist'
                    class="mb-3"
                    :columns="columns"
                    :data="inbox"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :sort-by="sortBy"
                    :sort-type="sortType"
                    :total="inbox.length"
                    :enableSearch="false"
                    @row-clicked="noticeClicked($event)"
                    :toBlur="true"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import DataList from '../cabinet/DataList.vue'

    export default {
        data:() => ({
            notices: [],
            inbox: [],
            currentPage: 1,
            perPage: 5,
            sortBy: 'created',
            sortType: 'desc',
            mounting: false
        }),
        computed: {
            columns: {
                get() {
                    return [
                        { value: 'senderUsername', text: 'Отправитель' },
                        { value: 'address', text: 'Адрес по задолженности' },
                        { value: 'amount_owed', text: 'Задолженность' },
                        { value: 'status', text: 'Статус' }
                    ]
                }
            }
        },
        components: {
            DataList
        },
        mounted() {
            this.checkOsi()

            this.$emit('setPageTitle', this.$t('debt-notice.inbox.title'))

            this.getInboxNotices()
        },
        methods: {
            langChange() {
                this.$emit('setPageTitle', this.$t('debt-notice.inbox.title'))
            },
            async checkOsi() {
                let isOsi = await this.$api.checkOsiStatus()
                if(isOsi) {
                    this.$router.replace('/debt-notice')
                } else{
                    this.mounting = false
                }
            },
            async getInboxNotices() {
                let url = process.env.VUE_APP_API_URL + '/debt-notice/inbox'

                await axios.get(url, {
                    withCredentials: true
                }).then(response => {
                    let r = response.data

                    if(r.result === 'SUCCESS') {
                        this.inbox = r.data
                    }
                })
            },
            noticeClicked(data) {
                this.$router.push(data.link)
            }
        }
    }
</script>